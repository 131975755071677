<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                        class="mt-1"
                        btnTitle="Go Back"
                        title="Create Contra"
                        @onClickCloseButton="goToList"
                />
                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Date</label>
                        <input
                            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                            tabindex="1"
                            type="date"
                            placeholder=""
                            v-model="contra.date"
                        >
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-xl-3 col-form-label">Amount</label>
                        <input
                                v-model="amount"
                                @input="onInputAmount"
                                type="number"
                                class="form-control text-end"
                                placeholder="Amount"
                        >
                    </div>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">

                        <div class="d-flex align-items-center justify-content-between filter-label">
                            <label for="colFormLabel" class="col-form-label">Party: </label>
                            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>

                        <AsyncSelect
                            placeholder="Select Party"
                            v-model="contactProfile"
                            :api-service="fetchContactProfiles"
                            :format-label="formatPatientLabel"
                        />
                    </div>

                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                                placeholder="Select Business"
                                class="w-100"
                                :options="businesses"
                                label="name"
                                :reduce="name => name.id"
                                v-model="contra.business_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Created By:</label>
                        <v-select
                            placeholder="Created By"
                            :options="companyUsers"
                            label="name"
                            :reduce="(name) => name.id"
                            v-model="contra.created_by"
                        />
                    </div>
                </div>
            </div>
            <div class="card p-2">
                <AddFormElement
                        class="mb-2"
                        :accountHeads="accountHeads"
                        v-for="(item, index) in contra.ledgers"
                        :key="index"
                        :index="index"
                        :item="item"
                />

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="contra.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-2 text-center">
                    <hr class="mb-1">
                    <button
                            :disabled="loading || saveNewLoader"
                            @click="saveContra(true)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save
                    </button>
                    <button
                            :disabled="loading || saveNewLoader"
                            @click="saveContra(false)"
                            class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & New
                    </button>
                    <button
                        :disabled="loading || saveNewLoader"
                        @click="saveContra(false, true)"
                        class="btn btn-primary me-1"
                    >
                        <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Save & Print
                    </button>
                    <button
                            @click="goToList"
                            class="btn btn-outline-secondary cancel-btn"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :isFilterable="true"
            :companyRoles="companyRoles"
        />
    </div>
</template>

<script>
import handleContra from '@/services/modules/contra'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import { inject } from 'vue'
import {generateQuery} from "@/services/utils/voucherNumberGenerator";
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/company/contra/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import figureFormatter from "@/services/utils/figureFormatter";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleRole from "@/services/modules/role";
import {useStore} from "vuex";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import pdfPrinterHelper from "@/services/utils/pdfPrinterHelper";
import handleImportTracker from "@/services/modules/procurement/importTracker";

export default {
    name: 'ContraCreate',
    components: {
        ContactDropdownFilter,
        AsyncSelect,
        TitleButton,
        AddFormElement,
        AddButton
    },
    data: () => ({
        amount: '',
        accountHeads: [],
        contactProfiles : [],
        businesses : [],
        saveNewLoader: false,
        companyRoles: [],
        companyUsers: [],
        contactProfile: null,
        contra: {
            company_id:'',
            contact_profile_id: null,
            business_id: null,
            created_by: null,
            date: '',
            note: '',
            mop_references: 'mop',
            voucher_type: 'contra_voucher',
            ledgers: [
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: null,
                    tax_rate: null,
                    taxable_amount: null,

                },
                {
                    account_head_id: null,
                    debit: null,
                    credit: null,
                    note: null,
                    tax_rate: null,
                    taxable_amount: null,
                }
            ]
        }
    }),
    computed: {
        isLoading () {
            return this.loading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        general () {
            return this.contra.ledgers
        },
        totalDebit () {
            let total = 0;
            this.general.map(i => {
                if(i.debit) total += i.debit
            })
            return total
        },
        voucherQuery () {
            return generateQuery(this.$route.params.companyId, 'contra_voucher', 'general_contra')
        }
    },

    watch: {
        contactProfile(newContact) {
            if(newContact) {
                this.contra.contact_profile_id = newContact.id;
            }
        }
    },
    
    methods: {
        
        onInputAmount () {
            this.contra.ledgers[0].credit = this.amount
            this.contra.ledgers[1].debit = this.amount
        },
        async getAccountHeads() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.data?.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getBusinesses(){
        try {
          let companyId = this.$route.params.companyId
          let res = await this.fetchBusinessList('?company_id=' + companyId)

          if(!res.status) {
          }
          if(res.status) {
            this.businesses = res.data;
          }

        } catch (err) {
          if(!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if(err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }

      },
        setVoucherNumberAndDate(withoutDate = false) {
            new Promise(async (resolve, reject) => {
                try {
                    if (!withoutDate) {
                        this.contra.date = new Date().toISOString().split('T')[0]
                    }
                    resolve();
                } catch (err) {
                    reject(err);
                }
            })

        },
        goToList() {
            this.$router.push({name: 'contra-voucher-list', 
                params: {
                    companyId: this.$route.params.companyId,
                    moduleId: this.$route.params.moduleId,
                    menuId: this.$route.params.menuId,
                    pageId: this.$route.params.pageId
                },
                query: {
                    start: this.start,
                    end: this.end
                }
            
            })
        },
        async resetForm() {
            this.amount = ''
            this.contra = {
                company_id: '',
                date: '',
                note: '',
                mop_references: 'mop',
                voucher_type: 'contra_voucher',
                ledgers: [
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: null,
                        tax_rate: null,
                        taxable_amount: null
                    },
                    {
                        account_head_id: null,
                        debit: null,
                        credit: null,
                        note: null,
                        tax_rate: null,
                        taxable_amount: null
                    }
                ]
            }
            this.contactProfile = null;
            await this.setVoucherNumberAndDate()
        },
        getFormData () {
            let formData = new FormData();
            Object.keys(this.contra).forEach(i => {
                if(i !== 'ledgers') {
                    formData.append(i, this.contra[i])
                }
            })
            let ledgers = this.general.map((ledger, index) => {
              ledger.contact_profile_id = this.contra.contact_profile_id;
              ledger.business_id = this.contra.business_id;
              ledger.mop_account_head_id = this.general[Math.abs(index-1)].account_head_id; //ledgers length must be 2
              return ledger
            })
            formData.append('general_ledgers', JSON.stringify(ledgers))
            return formData;
        },
        async saveContra (redirect = false, print = false) {
          //createContra
            this.contra.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.loading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createContra(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    await this.resetForm()
                    if(redirect) this.goToList()
                    if(print) this.goToPrint(res.data.id)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                    return ;
                }

                if(err.response && err.response.status !== 406) {
                    this.showError(err.response?.data.message)
                }
                if(err.response.data.data && err.response.data.data.show_modal) {
                    this.$refs.voucherValidationModal.openModal(err.response?.data.message)
                }
            } finally {
                this.loading = false
                this.saveNewLoader = false
            }
        },
        onOpenContactSearchModal () {
            this.$store.state.isModalOpenThree = true;
        },
        async getCompanyRoles () {
            const res = await this.fetchCompanyDefaultRoles(`?company_id=${this.$route.params.companyId}`);
            if (res.status) {
                this.companyRoles = res.data
            }
        },
        async getCompanyUsers () {
            const res = await this.fetchContacts(`?company_id=${this.$route.params.companyId}`);
            if (res.status) {
                this.companyUsers = res.data
            }
        },
        onSearchContact (contact) {
            this.contactProfile = contact
        },
        goToPrint(id) {
            this.fetchSingleContra(id).then( (res) => {
                if(res){
                    this.paymentRes = res.data;
                }}).then (() => {
                this.generatePdf(this.$route.params.companyId, this.paymentRes, 'contra')
            }).catch((err) => {
                console.log('Something went wrong', err);
            })
        },
    },
    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        const {decimalFormat} = figureFormatter();
        const store = useStore();
        const { generatePdf } = pdfPrinterHelper();
        const { fetchContacts } = handleImportTracker();

        const {
            fetchAccountHeads,
            createContra,
            fetchSingleContra,
            loading
        } = handleContra()

        const {
          fetchContactProfiles,
        } = handleContact()

        const {
          fetchBusinessList,
        } = handleCBusinesses()

        const { formatPatientLabel } = useAsyncDropdownHelper();
        const { fetchCompanyDefaultRoles } = handleRole();

        return {
            store,
            fetchAccountHeads,
            createContra,
            decimalFormat,
            fetchContacts,
            fetchSingleContra,
            loading,
            showError,
            showSuccess,
            generatePdf,
            fetchContactProfiles,
            fetchBusinessList,
            formatPatientLabel,
            fetchCompanyDefaultRoles,
        }
    },

    async mounted() {
        
        await this.getAccountHeads()
        await this.getBusinesses()
        await this.setVoucherNumberAndDate()
        await this.getCompanyRoles()
        await this.getCompanyUsers()
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
    @media screen and (min-width: 1200px) {
    .btn-close {
        /* margin-left: 200px; */
        /* font-size: 32px; */
        /* margin-top: 5px; */
        width: 30px;
        height: 30px;
        background-color: #E4E6EB;
        border-radius: 50%;
        /* margin-right: 30px; */
    }
    .btn-close:hover {
        width: 30px;
        height: 30px;
        background-color: #D8DADF;
        border-radius: 50%;
    }
    }
    @media screen and (max-width: 720px) {
    .btn-close {
        width: 25px;
        height: 25px;
        background-color: #E4E6EB;
        border-radius: 50%;
        margin-top: -5px;
        margin-right: -5px;
    }
    }
</style>
